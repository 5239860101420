<template>
  <v-container class="d-flex justify-center">
    <v-form :loading="isLoading" ref="form" style="width: 60%; padding-top: 30px">
      <v-card style="border: 1px solid #fff; background: rgba(0, 0, 0, 0.8);">
        <v-card-title>
          <span class="headline white--text">เปลี่ยนรหัสผ่าน</span>
        </v-card-title>
        <v-card-text>

          <v-text-field
            v-model="form.old_password"
            :error="error"
            label="รหัสผ่านเดิม"
            :rules="rules.password"
            dense dark outlined autofocus
          />
          <v-text-field
            type="password"
            v-model="form.new_password"
            :error="error"
            label="รหัสผ่านใหม่"
            :rules="rules.new_password"
            dense dark outlined
          />
          <v-text-field
            type="password"
            v-model="form.password_confirmation"
            :error="error"
            label="ยืนยัน รหัสผ่านใหม่"
            :rules="rules.password_confirmation"
            dense dark outlined
          />

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$refs.form.reset()" class="red--text" text>reset</v-btn>
          <v-btn color="primary" @click="onChangePassword">Send</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import validate from '../../helper/validates.js';
import { mapActions } from 'vuex'
export default {

  data() {
    return {
      isLoading: false,
      rules: {
        password: [
          validate.required(),
          validate.text(6, 20)
        ],
        new_password: [
          validate.required(),
          validate.text(6, 20)
        ],
        password_confirmation: [
          validate.required(),
          validate.text(6, 20),
          (v) => {
            return v === this.form.new_password || 'รหัสผ่านไม่ตรงกัน'
          }
        ],
      },
      errors:{},
      form: {
        old_password: '',
        new_password: '',
        password_confirmation: '',
      },
    }
  },
  created() { },
  mounted() { },
  methods: {
    ...mapActions('member', ['change_password']),
    ...mapActions('app', ['showSuccess']),
    async onChangePassword() {
      if(!this.$refs.form.validate()) return
      this.isLoading = true
      await this.change_password(this.form)
        .then((data) => {
          if (!data.error) {
            this.$router.push({ name: 'home' })
            this.showSuccess(data.message)
          }
        })
      this.isLoading = false
    },
  },
  computed: {}
}
</script>

<style>
</style>
